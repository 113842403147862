<template>
  <VariableKpiKorporatForm mode="Ubah" module="Variable Indikator Kinerja"> </VariableKpiKorporatForm>
</template>

<script>
import VariableKpiKorporatForm from './form';

const VariableKpiKorporatUpdate = {
  name: 'VariableKpiKorporatUpdate',
  components: { VariableKpiKorporatForm },
};

export default VariableKpiKorporatUpdate;
</script>
